<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="getData"
      :searchFlag="true"
      :exportShow="false"
    >
    </EForm>
    <div class="bgf4f4f5 pd20 mb20" v-if="list.length > 0">
      <el-radio-group v-model="radio" @change="getChange">
        <div :class="{ mb20: index != list.length - 1 }" v-for="(item, index) in list" :key="index">
          <el-radio :label="item.contractId">
            <!--                        区域、 摊位号 、 商户名称、 负责人 、身份证号、 联系电话、合同状态-->
            <div class="inlineblock">
              <div class="radio-div">
                <span class="pr20">区域：{{ item.areaName }}</span>
                <span class="pr20">摊位号：{{ item.boothCode }}</span>
                <span class="pr20">商户名称：{{ item.tenantName }}</span>
                <span class="pr20">负责人：{{ item.signPerson }}</span>
                <!--                        <span class="pr20">摊位面积：{{item.areas}}</span>-->
                <span class="pr20">身份证号：{{ item.idCard }}</span>
                <span class="pr20">联系电话：{{ item.phone }}</span>
                <span>合同状态：{{ item.contractStatusCn }}</span>
              </div>
            </div>
          </el-radio>
        </div>
      </el-radio-group>
    </div>
    <div class="flex flex-align-center">
      <div class="clred flex-1 tl fs30 fwb">
        交费：转账{{ total.transferAmount }}元 现金:{{ total.cashAmount }}元 合计：{{ total.totalAmount }}元 合计大写：{{
          total.totalAmountZH
        }}
        共{{ total.feeNum }}笔
      </div>
      <div class="ml10">
        <EButton type="primary" size="small" @click="initPrintData" style="height: 32px">收费打印凭证</EButton>
      </div>
    </div>

    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="false"
      @handleSelect="handleSelect"
      :needSelect="true"
      ref="table"
    >
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="dialogShow(scope.row.orderId)"> 明细 </EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>
    <!--        <EButton @click="payAndPrint" size="small" type="primary">打印确认</EButton>-->

    <EDialog
      :dialogVisible="dialogVisible"
      title="明细"
      @handleClose="dialogVisible = false"
      width="70%"
      @handleClick="dialogVisible = false"
    >
      <ETable :tableTitle="tableTitle2" :tableData="tableData2" :needPagination="false"> </ETable>
    </EDialog>
    <div style="display: none">
      <div class="printDiv" v-for="(printInfo, index) in printList" :key="index" ref="print">
        <div class="">
          <span class="w50px">
            {{ printInfo.areaName }}
          </span>
          <span class="w80px">
            {{ printInfo.tenantName }}
          </span>
          <span class="w180px">
            {{ printInfo.boothCode }}
          </span>
        </div>
        <ul class="voucherDiv1">
          <li v-for="(item, index) in printInfo.itemList">
            <div class="w-23">
              {{ item.takeReturnProjectName }}
            </div>
            <div class="w-15">
              {{ item.dealTypeCn }}
            </div>
            <div class="w-15">
              {{ item.payAmountCn }}
            </div>
            <div class="table-remark">
              <div>
                {{ item.chargingRange }}
              </div>
              <div>
                {{ item.remark }}
              </div>
            </div>
          </li>
        </ul>
        <div class="count">
          <span class="w60px">
            {{ printInfo.totalAmountUpper }}
          </span>
          <span class="w230px">
            {{ printInfo.totalAmount }}
          </span>
        </div>
        <div class="count">
          <span class="w35px">
            {{ printInfo.chargePersonName }}
          </span>
          <span class="w135px">
            {{ printInfo.createUserCn }}
          </span>
          <span class="w178px">
            {{ printInfo.payTime }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import vxRule from '@/assets/js/formValidate';
  import { digitUppercase } from '@/assets/js/common';
  import { otherMixin } from '@/components/mixin';
  import * as math from 'mathjs';

  export default {
    name: 'chargedList',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          // {
          //   label: '交费单号',
          //   prop: 'orderNum'
          // },
          // {
          //   label: '收费时间',
          //   prop: 'payTime'
          // },
          // {
          //   label: '区域',
          //   prop: 'areaName'
          // },
          // {
          //   label: '位置',
          //   prop: 'placeName',
          // },
          // {
          //   label: '摊位号',
          //   prop: 'boothCode'
          // },
          // {
          //   label: '商户名称',
          //   prop: 'tenantName'
          // },
          {
            label: '总金额',
            prop: 'totalAmount',
          },
          {
            label: '费用笔数',
            prop: 'feeNum',
          },
          {
            label: '转账金额',
            prop: 'transferAmount',
          },
          {
            label: '现金金额',
            prop: 'cashAmount',
          },
          {
            label: '录入人',
            prop: 'createUserCn',
          },
          {
            label: '财务确认',
            prop: 'confirmStatusCn',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        tableData: [],
        searchForm: {
          code: null,
          signPerson: null,
          idCard: null,
        },
        count: null,
        formColumns: [
          {
            title: '摊位号',
            type: 'text',
            property: 'code',
            show: true,
            keyUp: true,
          },
          {
            title: '负责人',
            type: 'text',
            property: 'signPerson',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'idCard',
            show: true,
          },
        ],
        radio: null,
        list: [],
        dialogVisible: false,
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
            width: '160px',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
            width: '160px',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '期数',
            prop: 'periods',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
        ],
        tableData2: [],
        selectData: [],
        total: {
          feeNum: 0,
          totalAmount: 0,
          totalAmountZh: 0,
          cashAmount: 0,
          transferAmount: 0,
        },
        printList: [], //打印的列表
      };
    },
    mounted() {
      this.tableData.forEach((item) => {
        this.$refs.table.$refs.table.toggleRowSelection(item.contractId, true);
      });
    },
    watch: {},
    created() {},
    components: { ETable, EDialog, EButton, EForm },
    beforeMount() {},
    methods: {
      /*
       * {"code":200,"success":true,"data":[{"areaName":"中国1","placeName":"上海","tenantName":"zzzz111111","boothCode":"111","chargePersonName":"系统管理员","payTime":"2021-02-20 10:32:55","createUserCn":"admin","totalAmount":888.00,"totalAmountUpper":"捌佰捌拾捌元","feeNum":1,"itemList":[{"createUser":1,"createTime":"2021-02-15 16:46:12","updateUser":1,"updateTime":"2021-02-15 16:46:12","status":0,"isDeleted":0,"orderItemId":507,"orderId":199,"orderNum":"CO20210215164611875894945","takeReturnProjectId":-1,"takeReturnProjectName":"抄表电费","year":"","month":"","feeBeginDate":"","feeEndDate":"","payAmount":444.00,"periods":-1,"dealType":1,"returnAmount":0,"isPay":1,"isPrint":1,"remark":"","isModify":-1,"isPayCn":"是","isPrintCn":"是","dealTypeCn":"收"},{"createUser":1,"createTime":"2021-02-15 16:46:12","updateUser":1,"updateTime":"2021-02-15 16:46:12","status":0,"isDeleted":0,"orderItemId":508,"orderId":199,"orderNum":"CO20210215164611875894945","takeReturnProjectId":-1,"takeReturnProjectName":"抄表服务管理费","year":"","month":"","feeBeginDate":"","feeEndDate":"","payAmount":444.00,"periods":-1,"dealType":1,"returnAmount":0,"isPay":1,"isPrint":1,"remark":"","isModify":-1,"isPayCn":"是","isPrintCn":"是","dealTypeCn":"收"}]}],"msg":"操作成功"}
       * */
      async payAndPrint(arr) {
        let res = await Http.payAndPrint(arr);
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getChange();
        }
      },
      async initPrintData() {
        let arr = [];
        this.selectData.forEach((item) => {
          arr.push({
            cashAmount: item.cashAmount,
            orderId: item.orderId,
            transferAmount: item.transferAmount,
          });
        });
        let res = await Http.initPrintData(arr);
        if (res.code == 200) {
          //外层
          //经营管理区 areaName
          //商户名称 tenantName
          //摊位号 boothCode
          //收款人 chargePersonName
          //录入人 createUserCn
          //合计大写 totalAmountUpper
          //合计 totalAmount
          //收款时间 payTime
          //itemList:
          //收费项 takeReturnProjectName
          //收退 dealTypeCn
          //金额 payAmount
          //备注 remark
          // this.getChange()
          this.printList = res.data;
          this.printList.forEach((item, index) => {
            this.$nextTick(() => {
              this.$print(this.$refs.print[index]);
            });
          });
          this.payAndPrint(arr);
        }
      },
      async dialogShow(orderId) {
        this.dialogVisible = true;
        let res = await Http.cmorderDetail({ id: orderId });
        if (res.code == 200) {
          this.tableData2 = res.data.items;
        }
      },
      async getData() {
        //code: null,
        // signPerson:null,
        // idCard:null
        if (!this.searchForm.code && !this.searchForm.signPerson && !this.idCard) {
          return false;
        }
        let res = await Http.selectContractByBooth(this.searchForm);
        if (res.code == 200) {
          this.list = []
          this.$nextTick(() => {
            res.data.forEach((item) => {
              //if(item.contractStatusCn==='执行中'||item.contractStatusCn==='已续签')
                this.list.push(item)
            });
          });
          
          //alert(JSON.stringify(res.data));
          //this.list = res.data;
        }
      },
      async getChange() {
        let res = await Http.getPayOrderList({
          id: this.radio,
          isPrint: '0',
        });
        if (res.code == 200) {
          this.tableData = res.data;
          this.$nextTick(() => {
            this.tableData.forEach((item) => {
              //this.$refs.table.$refs.table.toggleRowSelection(item, true);
            });
          });
        }
      },
      handleSelect(data) {
        this.selectData = data;
        this.total = {
          totalAmount: 0,
          feeNum: 0,
          totalAmountZH: '',
          transferAmount: 0,
          cashAmount: 0,
        };
        let totalAmount = 0;
        this.selectData.forEach((item) => {
          //合计等于每条订单的总金额之和
          totalAmount += item.totalAmount;
          this.total.transferAmount += math.round(item.transferAmount, 2);
          // if (item.transferAmount === 0 && item.cashAmount === 0) {
          //   //如果转账金额和现金金额都为0的情况下，现金金额+=该订单的总金额
          //   this.total.cashAmount += math.round(item.totalAmount, 2)
          // } else if (item.cashAmount === 0 && item.transferAmount !== 0) {
          //   //如果现金金额为空，但是转账金额不为空，那现金金额等于总金额减去现金金额
          //   this.total.cashAmount += math.round(item.totalAmount - item.transferAmount, 2)
          //   this.total.transferAmount += math.round(item.transferAmount, 2)
          // } else if (item.cashAmount !== 0 && item.transferAmount === 0) {
          //   //如果没有转账金额，现金有500,总金额有1000,那现金金额等于总金额1000
          //   this.total.cashAmount += math.round(item.totalAmount, 2)
          //   this.total.transferAmount += math.round(item.transferAmount, 2)
          // } else {
          //   //现金金额等于选中的订单现金金额总和，转账金额等于选中的订单金额总和
          //   this.total.transferAmount += math.round(item.transferAmount, 2)
          //   this.total.cashAmount += math.round(item.cashAmount, 2)
          // }
        });
        //合计等于现金金额+转账金额
        // let totalAmount = this.total.transferAmount + this.total.cashAmount
        this.total.totalAmount = math.round(totalAmount, 2);
        this.total.cashAmount = math.round(totalAmount - this.total.transferAmount, 2);
        this.total.feeNum = this.selectData.length;
        this.total.totalAmountZH=digitUppercase(this.total.totalAmount)
        // this.amount();
      },
      // 合计大写
      async amount() {
        let res = await Http.amountZh({ amount: this.total.totalAmount });
        if (res.code == 200) {
          this.total.totalAmountZH = res.data;
          console.log('合计大写', this.total.totalAmountZH);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.confirm-btn {
  text-align: right;
}

.printDiv {
  padding-top: 65px;
  margin-left: 66.6px;
  font-size: 12px;
  width: 616px;
  padding-bottom: 82px;

  .voucherDiv1 {
    font-size: 12px;
    margin-top: 38px;
    width: 100%;
    height: 205px;

    li {
      div {
        display: inline-block;
      }

      .w-23 {
        width: 23%;
      }

      .w-15 {
        width: 15%;
      }
    }
  }
}

.w50px {
  display: inline-block;
  box-sizing: content-box;
  width: 76px;
  padding-left: 76px;
}

.w80px {
  display: inline-block;
  width: 247px;
  padding-left: 57px;
}

.w180px {
  display: inline-block;
  width: 112.1px;
  padding-left: 45.6px;
}

.w60px {
  display: inline-block;
  width: 296.4px;
  padding-left: 87.4px;
}

.w230px {
  display: inline-block;
  width: 178.1px;
  padding-left: 51.8px;
}

.w35px {
  display: inline-block;
  width: 152px;
  padding-left: 53.2px;
  vertical-align: top;
}

.w135px {
  display: inline-block;
  width: 201.4px;
  padding-left: 30px;
  vertical-align: top;
}

.w178px {
  display: inline-block;
  width: 112.5px;
  padding-left: 45px;
}

.count {
  height: 25px;
  line-height: 25px;
}
.radio-div {
  display: flex;
  flex-wrap: wrap;
  span {
    line-height: 1.5;
  }
}
.table-remark{
  width: 45%;
  word-break: break-all;
  vertical-align: text-top;
}
</style>
